import { merge } from "lodash";

const { preset } = require("@rebass/preset");

const theme = merge(preset, {
  buttons: {
    primary: {
      fontFamily: "Helvetica, sans-serif",
      textTransform: "uppercase",
      fontSize: 3,
      paddingY: 3,
      paddingX: 4,
      borderRadius: "7px",
      backgroundColor: "rgb(32, 150, 255)",
      minWidth: "320px",
      "&:hover": {
        opacity: "0.5",
      },
      "&:focus": {
        opacity: 1,
      },
      "&:active": {
        opacity: 1,
      },
    },
    secondary: {
      fontSize: 2,
      paddingY: 3,
      paddingX: 3,
      minWidth: "250px",
      backgroundColor: "rgba(0,0,0,0.25)",
    },
  },
  fonts: {
    heading: "Helvetica, sans-serif",
    body: "Helvetica, sans-serif",
  },
  fontWeights: {
    heading: 700,
  },
  text: {
    heading: {
      textTransform: "uppercase",
    },
  },
  variants: {
    link: {
      textTransform: "uppercase",
      color: "black",
      opacity: 0.25,
      fontWeight: 700,
      textDecoration: "none",
      "&:hover": {
        opacity: 1,
      },
    },
  },
});

export default theme;
