import { Box, Text } from "rebass/styled-components";

function Footer() {
  return (
    <Box as="footer" color="rgba(0,0,0,0.5)" paddingTop={5}>
      <Text fontSize={0}>
        <Text marginBottom={2}>
          Investment management and advisory services are provided by Leung
          Enterprises, an SEC-registered investment adviser. Leung Enterprises
          offers a software-based financial advice engine that delivers
          automated financial planning tools to help users achieve better
          outcomes.
        </Text>
        <Text marginBottom={3}>
          All investing involves risk, including the possible loss of money you
          invest, and past performance does not guarantee future performance.
          Historical returns, expected returns, and probability projections are
          provided for informational and illustrative purposes, and may not
          reflect actual future performance. Leung Enterprises does not provide
          tax advice and investors are encouraged to consult with their personal
          tax advisors.
        </Text>
      </Text>
    </Box>
  );
}

export default Footer;
