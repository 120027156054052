import styled from "styled-components";

const Input = styled.input`
  font-family: inherit;
  font-size: 21px;
  padding: 7px;
  padding-left: 21px;
  min-width: 300px;
  border-radius: 7px;
  box-shadow: none;
  border-style: solid;
  border-color: rgb(240, 240, 240);
  border-width: 3.5px;

  &:focus {
    outline: none;
    border-color: rgb(224, 224, 224);
  }
`;

export default Input;
