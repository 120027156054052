import { useState } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { Box, Button, Flex, Heading, Text } from "rebass/styled-components";
import { Brand, Input, Question } from "../components";

enum InvestmentGoal {
  INCOME = "Income",
  GROWTH_AND_INCOME = "Growth and Income",
  GROWTH = "Growth",
  SPECULATION = "Speculation",
}

enum RiskTolerance {
  LOW = "Low",
  MEDIUM = "Medium",
  HIGH = "High",
}

enum Income {
  LESS_THAN_75_000 = "< $75,000",
  BETWEEN_75_000_AND_150_000 = "$75,000 - $150,000",
  GREATER_THAN_150_000 = "> $150,000",
}

enum LiquidNetWorth {
  LESS_THAN_150_000 = "< $150,000",
  BETWEEN_150_000_AND_400_000 = "$150,000 - $400,000",
  GREATER_THAN_400_000 = "> $400,000",
}

enum BirthYear {
  BEFORE_1997 = "< 1997",
  BETWEEN_1997_AND_2003 = "1997 - 2003",
  AFTER_2003 = "> 2003",
}

enum RetirementAge {
  BEFORE_OR_AT_35 = "35 years of age or before",
  AFTER_35 = "After 35 years of age",
}

function Quiz() {
  const location = useLocation();
  const [investmentGoal, setInvestmentGoal] = useState<InvestmentGoal>();
  const [riskTolerance, setRiskTolerance] = useState<RiskTolerance>();
  const [income, setIncome] = useState<Income>();
  const [liquidNetWorth, setLiquidNetWorth] = useState<LiquidNetWorth>();
  const [birthYear, setBirthYear] = useState<BirthYear>();
  const [retirementAge, setRetirementAge] = useState<RetirementAge>();
  const isQuizIncomplete =
    !investmentGoal ||
    !riskTolerance ||
    !income ||
    !liquidNetWorth ||
    !birthYear ||
    !retirementAge;
  const isEligible =
    investmentGoal === InvestmentGoal.SPECULATION &&
    riskTolerance === RiskTolerance.HIGH &&
    income !== Income.LESS_THAN_75_000 &&
    birthYear !== BirthYear.BEFORE_1997 &&
    retirementAge !== RetirementAge.AFTER_35;

  return (
    <Box flex={1} maxWidth="1100px" margin="0 auto">
      <Brand marginBottom={5} />
      <Heading fontSize={5} marginBottom={4}>
        {location.pathname !== "/quiz/results"
          ? "Let's build your strategy."
          : "Our preliminary recommendation"}
      </Heading>
      <Switch>
        <Route path="/quiz/goal">
          <Question
            title="1. What is your investment goal?"
            answerChoices={[
              InvestmentGoal.INCOME,
              InvestmentGoal.GROWTH_AND_INCOME,
              InvestmentGoal.GROWTH,
              InvestmentGoal.SPECULATION,
            ]}
            value={investmentGoal}
            onChange={(newValue) =>
              setInvestmentGoal(newValue as InvestmentGoal)
            }
            next="/quiz/risk"
          />
        </Route>
        <Route path="/quiz/risk">
          <Question
            title="2. What is your risk tolerance?"
            answerChoices={[
              RiskTolerance.LOW,
              RiskTolerance.MEDIUM,
              RiskTolerance.HIGH,
            ]}
            value={riskTolerance}
            onChange={(newValue) => setRiskTolerance(newValue as RiskTolerance)}
            next="/quiz/income"
          />
        </Route>
        <Route path="/quiz/income">
          <Question
            title="3. What is your annual pre-tax income?"
            answerChoices={[
              Income.LESS_THAN_75_000,
              Income.BETWEEN_75_000_AND_150_000,
              Income.GREATER_THAN_150_000,
            ]}
            value={income}
            onChange={(newValue) => setIncome(newValue as Income)}
            next="/quiz/net-worth"
          />
        </Route>
        <Route path="/quiz/net-worth">
          <Question
            title="4. What is your liquid net worth?"
            answerChoices={[
              LiquidNetWorth.LESS_THAN_150_000,
              LiquidNetWorth.BETWEEN_150_000_AND_400_000,
              LiquidNetWorth.GREATER_THAN_400_000,
            ]}
            value={liquidNetWorth}
            onChange={(newValue) =>
              setLiquidNetWorth(newValue as LiquidNetWorth)
            }
            next="/quiz/born"
          />
        </Route>
        <Route path="/quiz/born">
          <Question
            title="5. In what year were you born?"
            answerChoices={[
              BirthYear.BEFORE_1997,
              BirthYear.BETWEEN_1997_AND_2003,
              BirthYear.AFTER_2003,
            ]}
            value={birthYear}
            onChange={(newValue) => setBirthYear(newValue as BirthYear)}
            next="/quiz/retirement-plan"
          />
        </Route>
        <Route path="/quiz/retirement-plan">
          <Question
            title="6. When do you plan to retire?"
            answerChoices={[
              RetirementAge.BEFORE_OR_AT_35,
              RetirementAge.AFTER_35,
            ]}
            value={retirementAge}
            onChange={(newValue) => setRetirementAge(newValue as RetirementAge)}
            next="/quiz/results"
          />
        </Route>
        <Route path="/quiz/results">
          {isQuizIncomplete && <Redirect to="/quiz" />}
          {isEligible ? (
            <Box>
              <Heading marginBottom={4}>
                Congratulations! You're eligible.
              </Heading>
              <Text marginBottom={4}>
                Based on the answers you provided, you are eligible for our
                advisory services. Here is a preliminary portfolio we've created
                for you.
              </Text>
              <Box marginBottom={4}>
                <Heading>Preliminary Portfolio</Heading>
                <ul>
                  <li>66.5% U.S. Equities</li>
                  <li>28.5% International Equities</li>
                  <li>3.5% Ethereum</li>
                  <li>1.5% Bitcoin</li>
                </ul>
              </Box>
              <Text marginBottom={3}>
                This is not investment advice. In order to receive investment
                advice from us, you will need to register with us and provide
                more information to our advisory software. This portfolio is
                subject to change based on the information you provide to our
                software.
              </Text>
              <Text>
                Our full advisory software is currently under construction. To
                be notified when it is available, enter your email below.
              </Text>
            </Box>
          ) : (
            <Box>
              <Heading marginBottom={4}>
                Sorry, you're not eligible at this time.
              </Heading>
              <Text marginBottom={3}>
                You are not eligible for our advisory services at the moment.
                Enter your email below and we will reach out when we can serve
                you.
              </Text>
            </Box>
          )}
          <Flex
            as="form"
            name="quiz"
            method="POST"
            fontSize={3}
            paddingX={4}
            marginX={-4}
            marginTop={4}
            flexWrap="wrap"
            action="/success"
          >
            <input type="hidden" name="form-name" value="quiz" />
            <Input
              type="email"
              name="email"
              placeholder="Type your email address"
            />
            <Input
              type="hidden"
              name="eligible"
              value={isEligible.toString()}
            />
            <Box width={["100%", 0]} paddingLeft={[0, 3]} paddingTop={[3, 0]} />
            <Button type="submit">Submit</Button>
          </Flex>
        </Route>
        <Route path="/quiz">
          <Redirect to="/quiz/goal" />
        </Route>
      </Switch>
    </Box>
  );
}

export default Quiz;
