import { useHistory } from "react-router";
import { Box, Flex, FlexProps, Heading } from "rebass/styled-components";
import styled from "styled-components";

type StyledBrandProps = {
  unhoverable: boolean;
  large: boolean;
};

const StyledBrand = styled.div<StyledBrandProps>`
  opacity: ${({ unhoverable }) => (unhoverable ? 1 : 0.25)};
  transform: scale(${({ large }) => (large ? 1.1 : 0.8)});

  &:hover {
    opacity: 1;
  }
`;

type BrandProps = {
  unhoverable?: boolean;
  large?: boolean;
} & FlexProps;

const Brand: React.FC<BrandProps> = ({
  unhoverable = false,
  large = false,
  ...restProps
}) => {
  const history = useHistory();

  return (
    <Flex
      width="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      {...restProps}
    >
      <StyledBrand unhoverable={unhoverable} large={large}>
        <Box
          onClick={() => history.push("/")}
          textAlign="center"
          sx={{ cursor: "pointer" }}
        >
          <Heading fontSize={[4, 5]} marginBottom={0}>
            Leung Enterprises
          </Heading>
          <Heading
            fontSize={[2, 3]}
            marginBottom={3}
            sx={{ transform: ["scale(0.97)", "scale(1.04)"] }}
          >
            Investment Advisory Division
          </Heading>
        </Box>
      </StyledBrand>
    </Flex>
  );
};

export default Brand;
