import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Box, Button, Flex, Heading, Text } from "rebass/styled-components";
import { Footer } from "./components";
import { Documents, Home, Quiz, Success } from "./pages";

const WITHDRAWN = true;

const WithdrawnOverlay = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "#ffffffe8",
        zIndex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "not-allowed",
      }}
    >
      <Box
        bg="white"
        p={[3, 3, 5]}
        maxWidth={["90%", "90%", "50%"]}
        sx={{
          border: "1px solid black",
          borderRadius: "7px",
          cursor: "default",
        }}
      >
        <Heading
          fontSize={5}
          letterSpacing="-1.5px"
          sx={{ textTransform: "unset" }}
        >
          Announcement
        </Heading>
        <Text mt={3}>
          As of Friday, January 27th, 2023, the Leung Enterprises Investment
          Advisory Division has ceased operations.
        </Text>
        <Text mt={3}>
          The firm is filing to withdraw its SEC registration and{" "}
          <b>no longer offers investment advisory services</b>.
        </Text>
        <Text mt={3}>
          The{" "}
          <a href="https://software.leungenterprises.com">
            Leung Enterprises Software Development Division ↗
          </a>{" "}
          remains available to serve your software development needs.
        </Text>
        <Button
          mt={4}
          sx={{
            display: "flex",
            cursor: "pointer",
            textTransform: "unset",
            letterSpacing: "-1px",
          }}
          onClick={() =>
            (window.location.href = "https://software.leungenterprises.com")
          }
        >
          Software Development Division&nbsp;&nbsp;↗
        </Button>
      </Box>
    </Box>
  );
};

function App() {
  return (
    <Flex minHeight="100vh" width="100vw" flexDirection="column">
      <Flex paddingX={[3, 5]} paddingY={[5, 5]} flex={1}>
        <Flex flex={1} flexDirection="column">
          {WITHDRAWN && <WithdrawnOverlay />}
          <Router>
            <Switch>
              <Route path="/" exact>
                <Home />
              </Route>
              {WITHDRAWN ? (
                <Route>
                  <Redirect to="/" />
                </Route>
              ) : (
                <>
                  <Route path="/documents">
                    <Documents />
                  </Route>
                  <Route path="/quiz">
                    <Quiz />
                  </Route>
                  <Route path="/success">
                    <Success />
                  </Route>
                </>
              )}
            </Switch>
          </Router>
          <Footer />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default App;
