import { useHistory } from "react-router";
import { Box, Button, Heading, Text } from "rebass/styled-components";
import { Brand } from "../components";

function Success() {
  const history = useHistory();

  return (
    <Box flex={1} maxWidth="1100px" margin="0 auto">
      <Brand marginBottom={5} />
      <Heading fontSize={5} marginBottom={4}>
        Form Submitted
      </Heading>
      <Heading marginBottom={4}>
        We'll be in touch when we're ready to serve you.
      </Heading>
      <Text marginBottom={4}>
        We've received your information. We will let you know when we're ready
        to fully serve you.
      </Text>
      <Button sx={{ cursor: "pointer" }} onClick={() => history.push("/")}>
        Back to Home
      </Button>
    </Box>
  );
}

export default Success;
