import { useHistory } from "react-router";
import {
  Box,
  Button,
  Flex,
  Heading,
  Link as RebassLink,
} from "rebass/styled-components";
import { Brand } from "../components";

function Home() {
  const history = useHistory();

  return (
    <Flex alignItems="center" justifyContent="center" margin="0 auto" flex={1}>
      <Box marginBottom={5} maxWidth="1100px">
        <Brand marginBottom={5} />
        <Box textAlign="center">
          <Heading fontSize={5} marginBottom={5}>
            Documents
          </Heading>
          <Flex justifyContent="center" flexDirection="column">
            <Button
              sx={{ cursor: "pointer" }}
              onClick={() =>
                window.open("/docs/form_adv_part_2a_brochure.pdf", "_blank")
              }
              marginBottom={3}
            >
              Form ADV Part 2A Brochure
            </Button>
            <Button
              sx={{ cursor: "pointer" }}
              onClick={() => window.open("/docs/form_crs.pdf", "_blank")}
              marginBottom={3}
            >
              Form CRS
            </Button>
            <Button
              sx={{ cursor: "pointer" }}
              onClick={() =>
                window.open(
                  "/docs/form_crs_conversation_starters.pdf",
                  "_blank"
                )
              }
              marginBottom={3}
            >
              Form CRS Conversation Starters
            </Button>
            <Button
              sx={{ cursor: "pointer" }}
              onClick={() => window.open("/docs/code_of_ethics.pdf", "_blank")}
            >
              Code of Ethics
            </Button>
            <RebassLink href="#" onClick={() => history.goBack()} marginTop={5}>
              Back
            </RebassLink>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
}

export default Home;
