import { useHistory } from "react-router";
import { Box, Button, Flex, Image, Text } from "rebass/styled-components";
import { Brand } from "../components";
import stockChartImage from "../assets/images/stock-chart.jpg";

function Home() {
  const history = useHistory();

  return (
    <Flex alignItems="center" justifyContent="center" margin="0 auto" flex={1}>
      <Box marginBottom={5} maxWidth="1100px">
        <Brand marginBottom={4} unhoverable large />
        <Image
          src={stockChartImage}
          display="block"
          margin="0 auto"
          width={["100%", "50%"]}
        />
        <Box textAlign="center" marginTop={5}>
          <Text fontSize={3} marginBottom={5}>
            Leung Enterprises' investment advisory division provides investment
            advisory services to eligible investors. With our expertise in
            alternative asset classes like cryptocurrencies, we are pleased to
            be one of the first Gen-Z-focused investment advisory firms in the
            nation.
          </Text>
          <Flex justifyContent="center" flexWrap="wrap">
            <Button
              sx={{ cursor: "pointer" }}
              onClick={() => history.push("/quiz")}
            >
              Get Started
            </Button>
            <Box width={"100%"} height="25px" />
            <Button
              sx={{ cursor: "pointer" }}
              onClick={() =>
                window.open(
                  "https://adviserinfo.sec.gov/firm/summary/314878",
                  "_blank"
                )
              }
              variant="secondary"
            >
              View SEC Registration
            </Button>
            <Box width={["100%", "50px"]} height={["25px", 0]} />
            <Button
              sx={{ cursor: "pointer" }}
              onClick={() => history.push("/documents")}
              variant="secondary"
            >
              View Documents
            </Button>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
}

export default Home;
